import React from "react";
import Layout from "@layouts/Layout";

import WhyTemplate from "./Why";

const WhyPage = ({ data: initialData }) => {
  const data = initialData?.markdownRemark?.frontmatter;

  const seo = {
    ...(data?.seo || {}),
    image: data?.seo?.image?.childImageSharp?.fluid?.src,
  };

  return (
    <Layout seo={seo}>
      <WhyTemplate data={data} />
    </Layout>
  );
};

export default WhyPage;

export const whyPageQuery = graphql`
  query WhyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Why" } }) {
      frontmatter {
        seo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        header {
          title
          subTitle
          video {
            name
            publicURL
          }
        }
        whySection {
          category
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        approach {
          description
          category
          title
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image1 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        experience {
          category
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          images {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          buttonText
          buttonLink
        }
        contact {
          category
          title
          description
        }
      }
    }
  }
`;
