import React from "react";

function Rectangle40({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="381"
      height="443"
      fill="none"
      viewBox="0 0 381 443"
      className={className}
    >
      <rect width="381" height="443" fill="#E8E5F1" rx="8"></rect>
    </svg>
  );
}

export default Rectangle40;
