import React from "react";
import Header from "@layouts/Header";
import DefaultSection from "@sections/DefaultSection";
import Group115 from "@components/svg/Group115";

import Image from "@components/Image";
import Button from "@components/Button";
import Contact from "@sections/Contact";

import Details from "./components/Details";
import Innovation from "./components/Innovation";
import Authority from "./components/Authority";

import "./Why.styles.scss";

const HowTemplate = ({ data }) => {
  const header = data?.header || {};
  const whySection = data?.whySection || {};
  const approach = data?.approach || {};
  const experience = data?.experience || {};
  const contact = data?.contact || {};

  return (
    <div>
      <Header {...header} />
      <DefaultSection
        info={{
          ...whySection,
        }}
        image={whySection?.image}
      />

      <DefaultSection
        info={{
          ...approach,
        }}
        isReverse
        hasBackground
        rightSide={
          <div className="innovation__images">
            <div className="innovation__images--first">
              <Image data={approach?.image1} />
            </div>
            <div className="innovation__images--second">
              <Image data={approach?.image2} />
            </div>
          </div>
        }
      />
      <DefaultSection
        info={{
          ...experience,
        }}
        rightSide={
          <div className="authority">
            <div className="authority__svgs">
              {experience?.images?.map((item) => {
                return (
                  <div className="authority__image">
                    <Image
                      className="authority__image"
                      data={item.image}
                    />
                  </div>
                );
              })}
            </div>
            <div className="authority__button">
              <Button>
                <a href={experience?.buttonLink}>Enquire Now</a>
              </Button>
            </div>
          </div>
        }
        isReverse
        isVertical
      />

      <Contact
        info={{
          ...contact,
          isWhite: true,
        }}
      />
    </div>
  );
};

export default HowTemplate;
